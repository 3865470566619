<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card" style="min-height: 100vh">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Unlock requests</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Unlock requests
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" :md="currentUser.access_type == 'score' ? '6' : '3'">
                  <v-text-field
                    v-model="search.candidate"
                    v-on:keyup.enter="getUnlockReportRequests"
                    @input="search.candidate = $event !== null ? $event : ''"
                    label="Search by enrolment key or candidate name"
                    outlined
                    dense
                    clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" v-if="currentUser.access_type != 'score'">
                  <v-select
                    :items="scores"
                    v-on:keyup.enter="getUnlockReportRequests"
                    v-model="search.score_id"
                    label="SCORE"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                    @change="getExaminer"
                    v-model="selectExaminer"
                    outlined
                    dense
                    :search-input.sync="examinerSearch"
                    :items="examiners"
                    hide-selected
                    item-text="display_text"
                    return-object
                    label="Search by examiner name or email"
                  >
                    <template v-slot:label>
                      Search by examiner name or email <span class="text-danger"></span>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  md="2"
                  v-if="search.marked_obtained"
                >
                  <v-text-field
                    label="Mark"
                    v-model="search.marks"
                    v-on:keyup.enter="getUnlockReportRequests()"
                    @input="search.marks = $event !== null ? $event : ''"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click.prevent="getUnlockReportRequests()"
                    class="text-white btn btn-primary"
                    :loading="loading"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>

              <table class="table" v-if="!loading">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">Examiner</th>
                    <th class="px-3">Candidate</th>
                    <th class="px-3">Result</th>
                    <th class="px-3">Date requested</th>
                    <th class="px-3">Status</th>
                    <th class="px-3 text-center">Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr
                      v-if="unlock_report_requests.length > 0"
                      v-for="item in unlock_report_requests"
                    >
                      <td class="px-3">
                        <a
                          @click="openExaminer(item.examiner_id)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1"
                        >
                          {{ item.examiner_name }}
                        </a>
                      </td>
                      <td class="px-3">
                        <div class="font-weight-bolder ml-1">
                          {{ item.candidate_full_name }}
                        </div>
                        <a
                          @click="getCandidateSummary(item.candidate_exam_key)"
                          class="font-weight-bolder text-dark-75 mb-1 font-size-lg"
                        >
                          {{ item.candidate_exam_key }}
                        </a>
                      </td>
                      <td class="px-3">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                          {{ item.mark_obtained ? item.mark_obtained : "NA" }}
                        </span>
                      </td>
                      <td class="px-2">
                        <span class="font-weight-bolder text-dark-75 mb-1 font-size-lg">
                          {{ item.unlock_requested_date }}
                        </span>
                      </td>
                      <td class="px-2">
                        <span
                          class="badge font-size-lg ml-1"
                          v-bind:class="{ 'badge-info': item.is_main == 1 }"
                        >
                          {{ item.is_main_text ? item.is_main_text : "NA" }}
                        </span>
                        <div class="my-2">
                          <span class="badge ml-2" :class="item.is_result_available_to_user ? 'badge-success' : 'badge-warning'">
                            {{ item.is_result_available_to_user ? 'Issued to enroller' : 'Not issued to enroller' }}
                          </span>
                        </div>
                      </td>
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text
                                  v-if="
                                    item.is_examiner_unlock_requested
                                  "
                                  tag="div"
                                  class="navi-item"
                              >
                                <a
                                    @click="unlockMarkingForExaminer(item.id)"
                                    class="navi-link"
                                >
                                    <span class="navi-icon">
                                      <v-icon color="black">fas fa-lock</v-icon>
                                    </span>
                                  <span class="navi-text"
                                  >Unlock report for Examiner</span
                                  >
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text
                                  v-if="
                                    item.is_examiner_unlock_requested
                                  "
                                  tag="div"
                                  class="navi-item"
                              >
                                <a
                                    @click="ignoreUnlockMarkingForExaminer(item.id)"
                                    class="navi-link"
                                >
                                    <span class="navi-icon">
                                      <v-icon color="black">fas fa-ban</v-icon>
                                    </span>
                                  <span class="navi-text"
                                  >Decline request</span
                                  >
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="previewReport(item)" class="navi-link">
                                  <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                  </span>
                                  <span class="navi-text">Preview report</span>
                                </a>
                              </b-dropdown-text>

                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                              >
                                <a
                                  @click="manageReport(item.id, item.candidate_exam_key)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="black">fas fa-file</v-icon>
                                  </span>
                                  <span class="navi-text"
                                    >Manage report</span
                                  >
                                </a>
                              </b-dropdown-text>


                              <b-dropdown-text
                                tag="div"
                                class="navi-item"
                              >
                                <a
                                  @click="viewCandidateList(item.exam_day_id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="black">fas fa-eye</v-icon>
                                  </span>
                                  <span class="navi-text"
                                    >View candidate list</span
                                  >
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <tr v-if="unlock_report_requests.length == 0">
                    <td colspan="13">No pending unlock requests</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="unlock_report_requests.length > 0"
                class="pull-right mt-7"
                @input="getUnlockReportRequests"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                :disabled="loading"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import CandidatePracticalExamResultService from "@/services/candidate/practical-exam-result/CandidatePracticalExamResultService";
import ScoreService from "@/services/score/score/ScoreService";
import ExaminerService from "@/services/user/examiner/ExaminerService";

const practicalExamResult = new CandidatePracticalExamResultService();
const examiner = new ExaminerService();
const score = new ScoreService();

export default {
  data() {
    return {
      unlock_report_requests: [],
      total: null,
      perPage: null,
      page: null,
      menuDate: false,
      loading: false,
      search: {
        candidate: "",
        score_id: "",
      },
      scores: [],
      currentUser: {},
      examiners: [],
      selectExaminer: {},
      selectExaminers: [],
      examinerSearch: null,
    };
  },
  methods: {
    openExaminer(examinerId) {
      this.$tabs.open({
        name: "examiner-edit",
        params: { examinerId: examinerId },
      });
    },
    manageReport(resultId, candidateExamKey){
      this.$tabs.open({
        name: "practical-exam-result-report-edit",
        params: { 
          examKey: candidateExamKey,
          resultId: resultId 
        },
      });
    },
    viewCandidateList(examDayId){
      this.$tabs.open({
        name: "exam-day-scheduler-candidate",
        params: { 
          examDayId: examDayId,
        },
      });
    },
    getCandidateSummary(examKey) {
      this.$router.push({
        name: "candidate-summary",
        params: { examKey: examKey },
      });
    },
    getExaminer() {
      if (this.search.examiner_id == null || this.search.examiner_id == "") {
        this.search.examiner_id = [];
      }
      // this.search.examiner_ids.push(this.selectExaminer.id);
      this.selectExaminers.push(this.selectExaminer);
      // this.selectExaminer = {};
      this.examinerSearch = null;
    },
    getUnlockReportRequests() {
      this.loading = true;
      if (this.selectExaminer) {
        this.search.examiner_id = this.selectExaminer.id;
      } else {
        this.search.examiner_id = "";
      }

      practicalExamResult
        .getUnlockReportRequests(this.search, this.page)
        .then((response) => {
          this.unlock_report_requests = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    previewReport(item) {
      if (item.report_type == "scanned") {
        if (item.file_attachment) {
          window.open(item.file_attachment, "_blank");
        } else {
          this.$snotify.error("Report Not Found");
        }
      } else {
        practicalExamResult
          .previewExamReport(item.id)
          .then((response) => {})
          .catch((error) => {
            this.isLoading = false;
          });
      }
    },
    unlockMarkingForExaminer(resultId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            practicalExamResult
              .unlockMarkingForExaminer(resultId)
              .then((response) => {
                this.$snotify.success("Examiner Unlock request accepted successfully.");
                this.getUnlockReportRequests();
              })
              .catch((err) => {
                this.$snotify.error("Oops looks like something went wrong.");
              });
          }
        },
      });
    },
    ignoreUnlockMarkingForExaminer(resultId) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            practicalExamResult
                .ignoreUnlockMarkingForExaminer(resultId)
                .then((response) => {
                  this.$snotify.success("Examiner Unlock request ignored.");
                  this.getUnlockReportRequests();
                })
                .catch((err) => {
                  this.$snotify.error("Oops looks like something went wrong.");
                });
          }
        },
      });
    },
    getAllScore() {
      score
        .getScores()
        .then((response) => {
          this.scores = response.data.scores;
        })
        .catch((err) => {});
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
  },
  watch: {
    examinerSearch(val) {
      if (this.currentUser.score_id != "") {
        let data = {
          info: val,
          score_id: this.search.score_id,
        };

        examiner
          .search(data)
          .then((response) => {
            response.data.data.map((examiner) => {
              let data = examiner;
              data.display_text =
                examiner.first_name +
                " " +
                examiner.last_name +
                " | " +
                examiner.unique_identifier;
              this.examiners.push(data);
            });
          })
          .catch((err) => {})
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.examiners = [];
      }
    },
  },
  mounted() {
    this.getCurrentUser();
    this.getUnlockReportRequests();
    this.getAllScore();
  },
};
</script>
